import React from 'react'
import "../Style-Component/Hero.css";
import Header from './Header';
import hero_image from "../assets/hero_image.png";
import hero_image_back from "../assets/hero_image_back.png";
import heart from "../assets/heart.png";
import calories from "../assets/calories.png";
import {motion} from "framer-motion"
export const Hero = () => {
  const transaction={
    type:"spring",
    duration:3
  }
  const mobile=window.innerWidth<=768 ? true: false;
  return (
    <div className='hero'>
     <div className='blur hero-blur'></div>
      <div className="left-h">
         <Header/>
        <div className='the-best-ad'>
          <motion.div
           initial={{left:mobile ?"165px":"238px"}}
           whileInView={{left:"8px"}}
          transition={{...transaction,type:'tween'}}
          ></motion.div><span>the best fiteneess club in the</span>    
        </div>
           {/*Hero Heading */}
        <div className='hero-text'>
        <div>
          <span className='stroke-text'>Shape</span>
          <span>Your</span>
        </div>
        <div>
          <span>Ideal Body</span>
        </div>
        <div>
          <span>
          IN HERE WE WILL HELP YOU TO SHAPE AND BUILD YOUR IDEAL LIFE TO FULLEST
          </span>
        </div>
        </div>
          {/* Hero text ends Here */}
          {/* figure starts from here */}
          <div className='figure'>
          <div>
            <span>+140</span>
            <span>expect coachs</span>
          </div>
          <div>
            <span>+978</span>
            <span>members join</span>
          </div>
          <div>
           <span>+50</span>
            <span>fitness progress</span>
          </div>
          </div>
          {/* figure ends from here */}
          {/* Hero buttons starts from here */}
          <div className='hero-buttons'>
            <button className='btn'>
              Get Started
              </button>
            <button className='btn'>
              Learn More
              </button>
          </div>
          {/* Hero buttons ends from here */}
      </div>
      <div className='right-h'>
        <button className='btn'>Join Now</button>
        <motion.div 
         initial={{right:"-1rem"}}
         whileInView={{right:"4rem"}}
         transition={transaction}
         className='heart-rate'>
          <img src={heart} alt=""/>
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>
        {/* Hero image starts from here */}
        <motion.img src={hero_image} 
        alt="" 
        className="hero-image"
        initial={{right:"5rem"}}
        whileInView={{right:"8rem"}}
        transition={transaction}
        />
        
        <motion.img src={hero_image_back}
        alt=""
        initial={{right:"37rem"}}
        whileInView={{right:"28rem"}}
        transition={transaction}
        className="hero-image-back"
        />
        {/* Hero image ends from here */}
        {/* Calories starts from here */}
        <motion.div 
          initial={{right:"37rem"}}
          whileInView={{right:"28rem"}}
          transition={transaction}
        className='calories'>
          <img src={calories} alt="calories"/>
          <span>Calories Burns</span>
          <span>220 kcal</span>
        </motion.div>
        {/* Calories ends from here */}
      </div>
      </div>
  )
}
