import React,{useState} from 'react';
import "../Style-Component/Tesimonial.css";
import {testimonialsData} from "./testimonialsData.js"
import leftArrow from "../assets/leftArrow.png";
import rightArrow from "../assets/rightArrow.png";
import {motion} from "framer-motion";

 const Testimonial = () => {
  const transition={type:"spring",duration:3}
  const [selected,setselectedtestimonial]=useState(0);
  const t_test_monialData=testimonialsData.length;
  return (
    <div className='Testimonials'>
      <div className='left-t'>
       <span>Testimonial</span>
       <span className='stroke-text'>
         What they
       </span>
       <span>say about us</span>
       <motion.span
       initial={{opacity:0,x:-100}}
       transition={{...transition,duration:2}}
       whileInView={{opacity:1,x:0}}
       >
       {testimonialsData[selected].review}
       </motion.span>
       <span style={{color:'var(--orange)'}}>
        {testimonialsData[selected].name}</span>
       <span>{testimonialsData[selected].status}</span>
      </div>
      <div className='right-t'>
        <motion.div
        initial={{opacity:0,x:-100}}
        transition={{...transition,duration:2}}
        whileInView={{opacity:1,x:0}}>
        </motion.div>
        <motion.div
        initial={{opacity:0,x:100}}
        transition={{...transition,duration:2}}
        whileInView={{opacity:1,x:0}}>
        </motion.div>
        <motion.img
         key={selected}
         initial={{opacity:0,x:100}}
         animate={{opacity:1,x:0}}
         exit={{opacity:0,x:-100}}
         transition={transition}
        src={testimonialsData[selected].image} 
        alt=""/>
        <div className='arrows'>
          <img
          onClick={()=>{
            selected===0
            ?setselectedtestimonial(t_test_monialData-1):setselectedtestimonial((prev)=>prev-1);
          }}
           src={leftArrow} alt=""
          />
          <img 
          onClick={()=>{
           selected==t_test_monialData-1
           ?setselectedtestimonial(0)
           :setselectedtestimonial((prev)=>prev+1)
          }}
          src={rightArrow} alt=""/>

        </div>
      </div>
    </div>
  )
}

export default Testimonial;