import React from 'react'
import "../Style-Component/Programmers.css"
import rightArrow from "../assets/rightArrow.png";
import {programsData} from "./programsData.js"
export default  function Programmers(){
return (
    <div className='Programs' id="Programs">
     <div className='Programs-headers'>
       <span className='stroke-text'>Explore Our</span>
       <span>Programs</span>
       <span className='stroke-text'>to shape you</span>
     </div>
     <div className='programs-catagories'>
      {
      programsData.map((p_data)=>{
        return(
            <div className='catagory'>
                {p_data.image}
                <span>{p_data.heading}</span>
                <span>{p_data.details}</span>
                <div className='join-now'>
                <span>Join Now</span>
                <img src={rightArrow} alt=""/>
                </div>
            </div>
            )})
        }
    </div>
    </div>
  )
}

