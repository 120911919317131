import React from 'react'
import "../Style-Component/Programmers.css"
import {plansData} from "../Component/plansData";
import whiteTick from "../../Source/assets/whiteTick.png"
import "../Style-Component/Plan.css";
export const Plans = () => {
  return (
    <div className='plans-container'>
    <div className='blur blur-plan-1'></div>
    <div className='blur blur-plan-2'></div>
    <div className="Programs-headers" >
        <span className='stroke-text'>READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className='stroke-text'>NOW WITHUS</span>
    </div>
     {/**Plans card starts */}
     <div className='plans'>
      {plansData.map((plan,i)=>{
        return(
            <div className='plan' key={i}>
                {plan.icon}
                <span>{plan.name}</span>
                <span>{plan.price}</span>
                <div className='features'>
                 {plan.features.map((feature,i)=>{
                    return(
                        <div className='feature'>
                         <img src={whiteTick}/>
                         <span key={i}>{feature}</span>
                        </div>
                    )
                 })}
                 <div>
                    <span>See more benefit => </span>
                 </div>
                 <button className='btn'>Join Now</button>
                </div>
            </div>

        )
      })}
     </div>
    </div>
  )
}
