import React from 'react'
import "../Style-Component/Header.css"
import Logo from "../assets/logo.png";

const Header = () => {
const mobile=window.innerWidth=768 ?true:false;
const [menuOperand, setMenuOperand] = React.useState(false)
  return (
    <div className='header'>
       <img src={Logo} alt="logo" className='logo'/>
    
     <ul className='header-menu'>
     <li>Home</li>
     <li>Progress</li>
     <li>Why Use</li>
     <li>Plans</li>
     <li>Testimonials</li>
    </ul>
    </div>
  )
}

export default Header;