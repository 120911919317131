
import  React from "react";
import "../../my-app/src/Source/Style-Component/App.css";
import {Hero}  from "./Source/Component/Hero";
import { Join } from "./Source/Component/Join";
import { Plans } from "./Source/Component/Plans";
import Programmers from "./Source/Component/Programmers";
import { Reasons } from "./Source/Component/Reasons";
import Testimonial  from "./Source/Component/Testimonial";
import { Footer } from "./Source/Component/Footer";
function App() {
  return (
    <div className="App">
     <Hero/>
     <Programmers/>
     <Reasons/>
     <Plans/>
    <Testimonial/>
    <Join/>
    <Footer/>
    </div>
  );
}

export default App;
